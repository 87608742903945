<template>
  <div class="d-flex flex-column py-4">
    <div class="header-top">
      <h2 class="view-title">Semantic Analysis History</h2>
      <v-text-field label="Search" v-model="search.search" class="search" prepend-icon="mdi-magnify"></v-text-field>
    </div>
    <div>
      <template>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          sort-by="id"
          :sort-desc="sort_desc"
          :footer-props="{ 'items-per-page-options': items_per_page }"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page="10"
          class="elevation-1"
          loading-text="Loading... Please wait"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.keyword`]="{ item }">
            <a v-if="item.keyword" :href="googleLink(item.keyword)" target="_blank" class="col-keyword"
              >{{ item.keyword }}
            </a>
          </template>

          <template v-slot:[`item.comp_urls`]="{ item }">
            <div class="domain-list">
              <a v-for="url in item.comp_urls" :key="url" :href="externalLink(url)" target="_blank" class="url-domain"
                >{{ url }}
              </a>
            </div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatDate(item.created_at) }} </span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div class="cont-actions">
              <v-btn
                v-if="item.status === 'completed'"
                color="primary"
                @click="goToPath('/semantic-analysis/report/' + item.id)"
                outlined
              >
                Report
              </v-btn>
              <v-btn
                v-else-if="item.status === 'failed'"
                color="error"
                @click="goToPath('/semantic-analysis/await/' + item.id)"
                outlined
              >
                Failed
              </v-btn>
              <v-btn v-else color="warning" @click="goToPath('/semantic-analysis/await/' + item.id)" outlined>
                Creating
              </v-btn>
              <v-btn color="error" icon @click="showDialog(item)">
                <v-icon color="error"> mdi-delete </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <dialog-delete-report
          :show.sync="dialog_delete.show"
          :item="dialog_delete.item"
          title="Are you sure to delete this report?"
          description="This process is irreversible, and can no longer be recovered, would you like to continue?"
          :delete="deleteReport"
        ></dialog-delete-report>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import DialogDeleteReport from '@/components/dialog/DialogDelete.vue'

export default {
  components: { DialogDeleteReport },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        {
          text: 'Report ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Report Name', value: 'report_name' },
        { text: 'Keyword', value: 'keyword' },
        { text: 'Compare Domain', value: 'comp_urls' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'status' },
      ],
      items_per_page: [5, 10, 25, 50, 100],
      sort_desc: true,
      options: {},
      search: {
        search: '',
      },
      dialog_delete: {
        item: {},
        show: false,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getReportList()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getReportList()
      },
      deep: true,
    },
  },
  mounted() {
    this.getReportList()
  },
  methods: {
    ...mapActions({
      getSemanticAnalysisHistory: 'semantic_analysis/getSemanticAnalysisHistory',
      delete: 'semantic_analysis/delete',
    }),
    async getReportList() {
      this.loading = true
      await this.getSemanticAnalysisHistory(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data.items
            this.total = resp.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },

    formatDate(date) {
      return moment(date).fromNow()
    },
    clearSearch() {
      this.search.search = ''
    },
    externalLink(url) {
      if (url.includes('https://') || url.includes('http://')) {
        return url
      } else {
        return 'https://' + url
      }
    },
    async deleteReport() {
      this.loading = true
      this.dialog_delete.show = false
      await this.delete(this.dialog_delete.item.id)
        .then(resp => {
          this.dialog_delete.item = null
          this.getReportList()
        })
        .finally(() => {
          this.loading = false
        })
    },
    async showDialog(item) {
      this.dialog_delete.show = true
      this.dialog_delete.item = item
    },
    goToPath(path) {
      this.$router.push({ path })
    },
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.v-data-table >>> td {
  min-width: 120px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px !important;
  }
  .url-domain {
    min-width: 300px !important;
  }
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>
